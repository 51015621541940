import axios from "axios";
import { reportError } from "@/utils/error-reporting";
import API from "@/modules/Project/classes/API";

class DeveloperBankAccountAPI extends API {
    async getBankAccounts(
        developerId,
        q = { queries: {}, page: 1, perPage: 30 }
    ) {
        try {
            let response = await axios.get(
                `${DeveloperBankAccountAPI.apiURL}/developer-bank-accounts`,
                {
                    params: {
                        ...q.queries,
                        "developer:id": developerId,
                        page: q.page,
                        limit: q.perPage
                    }
                }
            );
            return response.data;
        } catch (error) {
            reportError(
                error,
                `[${DeveloperBankAccountAPI.panelName}] Get Developer Bank Accounts (developer: ${developerId})`
            );
            throw error;
        }
    }
    async getBankAccount(id) {
        try {
            const response = await axios.get(
                `${DeveloperBankAccountAPI.apiURL}/developer-bank-accounts/${id}`
            );

            return response.data;
        } catch (error) {
            reportError(
                error,
                `[${DeveloperBankAccountAPI.panelName}] Get Dev Bank Account (id: ${id})`
            );
            throw error;
        }
    }
}

export default DeveloperBankAccountAPI;
