<template>
  <div class="edit-project-phase">
    <fd-form @submit="submitForm" class="modal-content-wrapper">
      <modal-body class="p-2">
        <h4 class="mt-2 mb-3">Edit Phase</h4>

        <!-- Loader -->
        <div v-show="isLoading" class="spinner-container">
          <spinner></spinner>
        </div>
        <!-- Form -->
        <div v-show="!isLoading">
          <fd-input
            v-model="form.phase"
            class="col-12 mb-2"
            label="Name"
            name="projectPhaseName"
            type="text"
            :validators="[validator.required]"
          >
          </fd-input>
          <!-- Assign Bank Account -->
          <fd-form-section title="Assign Bank Account" class="mt-4 mxn-1 px-1">
            <assign-bank-accounts
              v-model="form.developerBankAccounts"
              :data="bankAccountsData"
              :pagination="bankAccountsPagination"
              class="col-12"
              :validators="[
                (val) =>
                  validator.required(val, {
                    err: 'Please select at least a bank account'
                  })
              ]"
            ></assign-bank-accounts>
          </fd-form-section>
        </div>
      </modal-body>
      <modal-footer>
        <div class="row justify-content-end p-2">
          <button type="button" class="btn" @click="$emit('cancel')">
            Cancel
          </button>
          <button class="btn main ml-1">Update</button>
        </div>
      </modal-footer>
    </fd-form>
  </div>
</template>

<script>
import { required } from "@/components/GlobalComponents/FormComponents/Validator/rules";

import ManagerRoles from "@/modules/Project/classes/ManagerRoles";
import ProjectPhaseAPI from "@/modules/Project/api/projectPhase";
import DeveloperBankAccountAPI from "@/modules/Project/api/developerBankAccount";

import ProjectPhaseModel from "@/modules/Project/models/ProjectPhaseModel";

export default {
  components: {
    Spinner: () =>
      import("@/components/GlobalComponents/LoaderComponent/Spinner"),
    AssignBankAccounts: () =>
      import(
        "@/components/GlobalComponents/Shared/Developer/AssignBankAccounts"
      )
  },
  mixins: [],
  props: {
    type: {
      type: String,
      required: true,
      validator: (val) => new ManagerRoles().getRoles().includes(val)
    },
    projectId: {
      required: true
    },
    phaseId: {
      required: true
    },
    developerId: {
      required: true
    }
  },
  data: function () {
    return {
      bankAccAPI: new DeveloperBankAccountAPI(this.type),
      phaseAPI: new ProjectPhaseAPI(this.type),

      isLoading: false,

      form: {
        phase: "",
        project: "",
        developerBankAccounts: []
      },

      bankAccountsData: [],
      bankAccountsPagination: {},

      validator: {
        required: required
      }
    };
  },
  computed: {},
  watch: {},
  created: function () {},
  beforeDestroy: function () {},
  mounted: function () {
    this.init();
  },
  methods: {
    async init() {
      try {
        this.isLoading = true;
        this.form.project = this.projectId;
        await Promise.all([this.getPhase(), this.getDeveloperBankAccounts()]);
        this.isLoading = false;
      } catch (error) {
        this.isLoading = false;
      }
    },

    async getPhase() {
      try {
        let data = await this.phaseAPI.getProjectPhase(this.phaseId);
        this.form.phase = data.name;
        this.form.developerBankAccounts = data.developerBankAccounts;
      } catch (error) {
        this.$notify({
          group: "alert",
          type: "error",
          title: "Error",
          text: "Failed to get phase data to edit. Please try again later."
        });
      }
    },

    async getDeveloperBankAccounts() {
      try {
        let data = await this.bankAccAPI.getBankAccounts(this.developerId, {
          queries: {},
          page: 1,
          perPage: 30
        });

        this.bankAccountsData = this._.cloneDeep(data.data);
        this.bankAccountsPagination = this._.cloneDeep(data.meta.pagination);
      } catch (error) {
        this.$notify({
          group: "alert",
          type: "error",
          title: "Error",
          text:
            "Failed to get developer's bank accounts. Please try again later."
        });
      }
    },
    submitForm() {
      this.$emit(
        "submit",
        this.phaseId,
        ProjectPhaseModel.putPayload(this.form)
      );
    }
  }
};
</script>

<style lang="scss">
.edit-project-phase {
  min-width: 500px;

  .spinner-container {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 300px;
    min-width: 300px;
  }
}
</style>
