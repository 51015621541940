class ProjectPhaseModel {
    static postPayload(e) {
        return {
            phase: e.phase || "",
            project: e.project || "",
            developerBankAccounts:
                e.developerBankAccounts.map((data) => {
                    return { id: data.id };
                }) || []
        };
    }

    static putPayload(e) {
        return {
            phase: e.phase || "",
            project: e.project || "",
            developerBankAccounts:
                e.developerBankAccounts.map((data) => {
                    return { id: data.id };
                }) || []
        };
    }
}
export default ProjectPhaseModel;
